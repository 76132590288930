<template>
  <div class="card-custom" v-if="ready">
    <div class="row" v-if="$store.api.user.role === 1">
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Prospects</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.prospects}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Clients Pautions</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.client_pautions}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Attestation active</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.attestation}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Inscrits Pautions</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.inscrit_pautions}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <div class="col-md-4 offset-md-4 mt-3">
        <div class="card border-left-primary h-100 py-2">
          <div class="card-body">
            <b-row>
              <b-col md="6">
                <b-form-group label="Date de debut">
                  <b-form-input type="date" v-model="date_start"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date de fin">
                  <b-form-input type="date" v-model="date_end"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-row>
    <b-card class="mt-3">
      <b-row class="text-center">
        <b-col md="2">
          <b-form-group label="Reference du client">
            <b-form-input v-model.number="filters['id']" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Nom du client">
            <b-form-input v-model="filters['lead.nom']" ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Statut du souscription">
            <treeselect v-model="filters['status_id']" :multiple="true" :options="params.SouscriptionStatus"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Partenaire">
            <treeselect v-model="filters['partenaire.id']" :multiple="true" :options="params.Partenaire"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Apporteur">
            <treeselect v-model="filters['partenaire.apporteur_id']" :multiple="true" :options="params.Apporteur"/>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-block text-center mt-3">
        <b-button variant="danger" class="me-2" @click="filters.ifDateBail = !filters.ifDateBail; datatableInit();">
          Date effet renseignée
        </b-button>

        <b-button  v-show="loaderSearch === false" variant="primary" @click="datatableInit()">
          Recherche
        </b-button>
        <b-button  v-show="loaderSearch === true" variant="primary">
          <i class="fas fa-spinner fa-spin"></i>
        </b-button>

        <b-button v-show="loaderExcel === false" class="ms-2" variant="success" @click="toExcel()" v-if="$store.api.user.role === 1">
          <i class="fas fa-file-excel text-white"></i>
        </b-button>        
        <b-button v-show="loaderExcel === true" class="ms-2" variant="success" v-if="$store.api.user.role === 1">
          <i class="fas fa-spinner fa-spin"></i>
        </b-button>        
      </div>
    </b-card>
    <b-card class="mt-3">
      <div class="">
        <h4 class="card-title d-inline">Liste des inscrits ({{datatable.data.length}})</h4>
      </div>
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(id)="data">
          <a :href="'/#/admin/inscrit/'+data.value">{{data.value}}</a>
        </template>
        <template #cell(logement.date_bail)="data">
          {{formatDate(data.value)}}
        </template>
        <template #cell(date_create)="data">
          {{$store.api.timestampToDate(data.value, false)}}
        </template>
        <template #cell(paiement.type_id)="data">
          {{$store.api.getParam(params.PaiementType, data.value).titre}}
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.SouscriptionStatus, data.value).color">{{$store.api.getParam(params.SouscriptionStatus, data.value).titre}}</b-button>
        </template>
        <template #cell(action)="data">
          <b-button size="sm" variant="indigo" class="text-white" :href="'/#/admin/inscrit/'+data.item.id" style="background: #6610f2;"><i class="fas fa-eye"></i></b-button>
        </template>
        <template #cell(partenaire)="data">
          {{getPartenaire(data.item)}}
        </template>
        <template #cell(apporteur)="data">
          {{getApporteur(data.item)}}
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  computed:{
    date_start:{
      get(){
        var val = this.filters['min_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['min_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 00:00:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['min_date_create'] = Math.floor(gmt);
      }
    },
    date_end:{
      get(){
        var val = this.filters['max_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['max_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 23:59:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['max_date_create'] = Math.floor(gmt);
      }
    }
  },
  components: {
    Treeselect
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    loaderExcel: false,
    params:{
      SinistreType:[],
      SouscriptionStatus:[]
    },
    fields: [
      {
        key: 'id',
        label: 'Ref.',
        sortable:true,
      },
      {
        key: 'date_create',
        label: "Date inscription",
        sortable:false,
      },
      {
        key: 'logement.date_bail',
        label: "Date effet contrat",
        sortable:false,
      },
      {
        key: 'lead.nom',
        label: "Nom",
        sortable:false,
      },
      {
        key: 'lead.prenom',
        label: "Prenom",
        sortable:false,
      },
      {
        key: 'lead.email',
        label: "Mail",
        sortable:false,
      },
      {
        key: 'lead.telephone',
        label: "Telephone",
        sortable:false,
      },
      {
        key: 'paiement.type_id',
        label: "Type paiement",
        sortable:false,
      },
      {
        key: 'status_id',
        label: "Statut souscription",
        sortable:false,
      },
      {
        key: 'action',
        label: "Action",
        sortable:false,
      },
      {
        key: 'partenaire',
        label: "Partenaire",
        sortable:false,
      },
      {
        key: 'apporteur',
        label: "Apporteur",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
    },
    sort:{
      key: 'id',
      value: 'DESC'
    },
    filters:{
      "status_id":null,
      "type_id":null,
      "id":null,
      "min_date_create":null,
      "max_date_create":null,
      "ifDateBail":false
    }
  }),
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/admin/inscrits', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
    toExcel(){
      if(this.loaderExcel === true){
        return false;
      }
      this.loaderExcel = true;
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: null,
        page: this.datatable.currentPage
      }      
      this.$store.api.ajax('/admin/inscrits', params, (res) => {
        var rows = [];
        var title = ['Ref', 'Date inscription', 'Nom', 'Prenom', 'Mail', 'Telephone', 'Type paiement', 'Statut souscription', 'Partenaire', 'Apporteur'];
        rows.push(title.join(';'));
        res.data.forEach((r) => {
          var tmp = [];
          tmp.push(r.id);
          tmp.push(r.logement.date_bail);
          tmp.push(r.lead.nom);
          tmp.push(r.lead.prenom);
          tmp.push(r.lead.email);
          tmp.push(r.lead.telephone);
          tmp.push(this.$store.api.getParam(this.params.PaiementType, r.paiement.type_id).titre);
          tmp.push(this.$store.api.getParam(this.params.SouscriptionStatus, r.status_is).titre);
          tmp.push(this.getPartenaire(r));
          tmp.push(this.getApporteur(r));
          rows.push(tmp.join(';'));        
        });
        rows = rows.join("\n");
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(rows);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Export.csv';
        hiddenElement.click();
        this.loaderExcel = false;
      });      
    },
    getPartenaire(obj){
      if(obj.partenaire === undefined){
        return 'Pautions Direct'
      }
      var check = this.params.Partenaire.find(p => p.id === obj.partenaire.id);
      if(check === undefined){
        return 'Pautions Direct'
      }
      return check.raison_social
    },
    getApporteur(obj){
      if(obj.partenaire === undefined){
        return 'Pautions Direct'
      }
      var check = this.params.Partenaire.find(p => p.id === obj.partenaire.id);
      if(check === undefined){
        return 'Pautions Direct'
      }
      var check2 = this.params.Apporteur.find(a => a.id === check.apporteur_id);
      if(check2 === undefined){
        return 'Pautions Direct'
      }
      return check2.raison_social
    }
  },
  beforeMount() {
    this.$store.api.ajax(`/admin/params?type=inscrit&start=${this.date_start}&end=${this.date_start}`, null, (res) => {
      if(res.status === true){
        res.data.SouscriptionStatus.forEach((item) => {
          item.label = item.titre;
        });
        res.data.Apporteur.forEach((item) => {
          item.label = item.raison_social;
        });
        res.data.Partenaire.forEach((item) => {
          item.label = item.raison_social;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    this.datatableInit();
  },
}
</script>
<style>
</style>
